import React from "react";
// Customizable Area Start
import {
  TextField,
  IconButton,
  withStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import DarkStoreManagementController from "./DarkStoreManagementController.web";
import CustomPagination from "../../../components/src/Pagination.web";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import {
  LocationIcon,
  GroupIcon,
  KebabIcon,
  SearchIcon,
} from "../../../components/src/assets.web";
import UpdateModal from "../../../components/src/UpdateModal.web";
import CustomPopup from "../../../components/src/CustomPopup.web";
import AlertPopup from "../../../components/src/AlertPopup.web";
import VendorManagementFormCommon from "../../../components/src/VendorManagementFormCommon.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";
import SuccessPopup from "../../../components/src/SuccessPopup.web";
import {IAddressProps} from "../../../components/src/types";
import { VendorManagementForm1Schema } from "../../../components/src/CommonFunctions";
const warehouseImg = require("../../../components/src/assets/images/warehouse.jpeg");

const styles: any = {
  container: {
    margin: "24px",
    background: "#fff",
    borderRadius: "10px"
  },
  textField: {
    margin: "24px",
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
    "& fieldset": {
      border: "none",
    },
    background: "#F7F7F7",
    width: "410px",
    borderRadius: "4px",
  },
  detailsContainer: {
    paddingLeft: "24px",
    "& h6": {
      fontSize: "16px",
      fontFamily: "gilroy-bold",
    },
  },
  warehouseList: {
    display: "inline-flex",
    flexWrap: "wrap",
    //width:"max-content"
  },
  warehouseGrid: {
    maxWidth: "max-content",
    cursor: "pointer",
  },
  warehouseContainer: {
    border: "1px solid #E6E7EC",
    borderRadius: "6px",
    margin: "auto 22px 22px 0px",
    display: "inline-flex",
    width: "367px",
    height: "125px",
  },
  warehouseImg: {
    // margin:"16px",
    padding: "16px",
    width: "max-content",
    maxWidth: "max-content",
    height: "min-content",
    "& img": {
      width: "78px",
      height: "70px",
      borderRadius: "6px",
    },
  },
  warehouseDetails: {
    margin: "16px auto 16px 0",
    maxWidth: "158px",
    "& h6": {
      lineHeight: "1em !important",
      marginBottom: "8px",
    },
  },
  detail: {
    display: "inline-flex",
    width: "100%",
    paddingBottom: "5.5px",

    //padding:"4px 0",
    "& *": {
      fontFamily: "gilroy-regular",
    },
    "& p": {
      margin: "0px !important",
      fontSize: "12px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "170px",
      height: "16px",
    },
    "& svg": {
      marginRight: "12px",
    },
  },
  updateBtn: {
    maxWidth: "max-content",
    "& button": {
      height: "42px",
      //margin:"8px"
    },
  },
  pagination: {
    margin: "0 24px",
    paddingBottom: "18px",
  },
  formContainer: {
    width: "818px",
    background: "#fff",
    borderRadius: "10px",
    "& p": {
      fontFamily: "gilroy-bold !important",
      marginBottom: "24px",
      marginLeft: "8px",
    },
  },
  legalname:{
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp:3,
    WebkitBoxOrient: 'vertical'
  }
};

class DarkStoreManagement extends DarkStoreManagementController {
  // Customizable Area Start

  renderDataList = (classes:any) => {
    const { currentDataList, anchorEl, open } = this.state;
    if(currentDataList.length > 0){
      return currentDataList.map((warehouse: any) => (
                    <Grid
                      key={warehouse.id}
                      item
                      xl={4}
                      lg={6}
                      md={6}
                      xs={12}
                      sm={12}
                      className={classes.warehouseGrid}
                    >
                      <Grid
                        container
                        key={warehouse.sNo}
                        className={classes.warehouseContainer}
                      >
                        <Grid
                          item
                          xl={3}
                          lg={3}
                          md={3}
                          xs={12}
                          sm={12}
                          className={classes.warehouseImg}
                          id="navigate-to-details"
                          onClick={() =>
                            this.navigateToWarehouseDetails(warehouse.id)
                          }
                        >
                          <img
                            src={warehouse.attributes?.photo?.url || warehouseImg}
                            alt="warehouse"
                          />
                        </Grid>
                        <Grid
                          item
                          xl={7}
                          lg={7}
                          md={7}
                          xs={12}
                          sm={12}
                          className={classes.warehouseDetails}
                          id="navigate-to-details-2"
                          onClick={() =>
                            this.navigateToWarehouseDetails(warehouse.id)
                          }
                        >
                      <Typography variant="h6" className={classes.legalname}>
                          {warehouse.attributes.legal_name}
                          </Typography>
                          <div className={classes.detail}>
                            <GroupIcon /> <p>{warehouse.attributes.contact}</p>
                          </div>
                          <div className={classes.detail}>
                            <LocationIcon />{" "}
                            <p>{warehouse.attributes.address}</p>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          xs={2}
                          sm={2}
                          className={classes.updateBtn}
                        >
                          <IconButton
                            className="update-dropdown"
                            id="EditBtn"
                            onClick={(e: any) =>
                              this.handleUpdateDropdown(e, warehouse)
                            }
                          >
                            <KebabIcon />
                          </IconButton>
                          <UpdateModal
                            open={open}
                            editHandler={this.openEditHandler}
                            deleteHandler={this.handleDeleteAlert}
                            anchorEl={anchorEl}
                            handleClose={this.handleClose}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
    }else{
      return <></>;
    }
  }

  renderDarkstore = ({
    hasAccess,
    classes,
  }: {
    hasAccess: boolean;
    classes: any;
  }) => {
    const {
      isLoading,
      dataList,
      currentPage,
      rowsCount,
      currentDataList,
      startIndex,
      endIndex,
      noDarkstoreLinked
    } = this.state;

    if (!isLoading && hasAccess !== null) {
      if (!hasAccess) {
        return (
          <NoAccessPage
            id="noAccessPage"
            description="Sorry, you don't have the permission"
          />
        );
      }else if(noDarkstoreLinked){
        return <NoAccessPage
        id="noDarkstoreLinkedpage"
        description="Sorry, No darkstore is added to this account"
      />
      } else {
        return (
          <div className={classes.container}>
            <TextField
              className={classes.textField}
              onChange={this.handleSearch}
              placeholder="Search by Warehouse Names"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <div className={classes.detailsContainer}>
              <Grid container className={classes.warehouseList}>
                {this.renderDataList(classes)}
              </Grid>
            </div>
            <div className={classes.pagination}>
              {currentDataList.length > 0 ? (
                <CustomPagination
                  dataLength={dataList.length}
                  handlePrevNext={this.handlePrevNext}
                  currentPage={currentPage}
                  startIndex={startIndex}
                  rowsCount={rowsCount}
                  handlePagination={this.handlePagination}
                  endIndex={endIndex}
                />
              ) : (
                <Typography className="no_records_found">
                  No records found
                </Typography>
              )}
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, ...restProps } = this.props;
    const { isEdit, isDeletePopup } = this.state;
    const { userProfile } = restProps;
    const groups = userProfile && userProfile.attributes.groups.data &&
      userProfile.attributes.groups.data.length > 0
        ? userProfile.attributes.groups.data[0].attributes.settings
        : {};
    const hasAccess = !!groups ? groups.dark_store_management : null;

    const initialValues = {
      legal_name: this.state.legal_name,
      district: this.state.district,
      address: this.state.address,
      contact: this.state.contact,
      city: this.state.city,
      country: this.state.country,
      email: this.state.email,
      trade_name: this.state.trade_name,
      state: this.state.state,
      gstin: this.state.gstin,
      store_size: this.state.store_size,
      latitude: 0,
      longitude: 0,
      pincode: this.state.pincode,
      pan: this.state.pan,
      limit: this.state.limit,
      authorized_person_contact: this.state.authorized_person_contact,
      bank_branch: this.state.bank_branch,
      photo: { url: null },
      ifsc: this.state.ifsc,
      authorized_person_designation: this.state.authorized_person_designation,
      bank_account_number: this.state.bank_account_number,
      authorized_person_name: this.state.authorized_person_name,
    };
    return (
      <GlobalStoreDashboardLayout
        {...restProps}
        headerTitle="Dark Store Management"
        actionHandler={this.handleNavigateToNewVendorForm}
        headerAction="New"
        subTitle=""
        hasAccess={hasAccess}
      >
        {this.renderDarkstore({ hasAccess, classes })}
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={VendorManagementForm1Schema}
          onSubmit={(values: any) => this.handleSubmitUpdateForm(values)}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <CustomPopup
                title="Edit"
                isOpen={isEdit}
                actionHandler={handleSubmit}
                action="Save"
                handleClose={this.handleClose}
              >
                <VendorManagementFormCommon
                  image={this.state.image}
                  isEdit={true}
                  values={values}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  containerStyles={classes.formContainer}
                  hiddenFileInput={this.hiddenFileInput}
                  handleImageChange={this.handleImageChange}
                  handleClick={this.handleClick}
                  validationSchema={VendorManagementForm1Schema}
                  autoFillAddressHandler={(addressComponents:IAddressProps[] | undefined,address:string) => {
                    setFieldValue("address", address);
                    if(addressComponents){
                      setFieldValue("pincode", Number(addressComponents[4].long_name));
                    setFieldValue("country", addressComponents[3].long_name);
                    setFieldValue("state", addressComponents[2].long_name);
                    setFieldValue("district", addressComponents[1].long_name);
                    setFieldValue("city", addressComponents[0].long_name);
                    }
                    this.handleCloseLocation()
                }}
                handleLocationFields={(lat:number, lng:number) => {
                    setFieldValue("latitude",lat)
                    setFieldValue("longitude", lng)
                }}
                  isOpenSearch={this.state.isOpenSearch}
                  onChangeHandler={this.onChangeHandler}
                  searchFieldHandler={this.searchFieldHandler}
                  searchKey={this.state.searchKey}
                  isOpenMap={this.state.isOpenMap}
                  selectLocationHandler={this.selectDarkstoreLocationHandler}
                  handleClose={this.handleCloseLocation}
                />
              </CustomPopup>
            );
          }}
        </Formik>

        <AlertPopup
          isOpen={isDeletePopup}
          handleClose={this.handleClose}
          cancelHandler={this.handleClose}
          actionHandler={this.deleteDarkstore}
          action="Delete"
          description={
            <Typography variant="h6">
              Are You Sure that you want to <span>Delete</span> this vendor ?
            </Typography>
          }
        />
        <SuccessPopup
          description={
            <Typography variant="h6">{this.state.successMsg}</Typography>
          }
          isOpen={this.state.isVendorUpdated}
          handleClose={this.handleSuccessClose}
        />
      </GlobalStoreDashboardLayout>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
export { DarkStoreManagement };
export default withStyles(styles)(withRouter(DarkStoreManagement));
// Customizable Area End
